import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const WhyScience = () => (
  <Layout title="Why Science?" className="annual-challenge has-video">
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">Why Science?</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Why Science?</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../anatomy-of-challenge" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <div className="videoWrapper">
                <Video
                  guidSrc="edcbabd9-9b48-4a50-899f-e5436ba9da0d"
                  className="de-vid"
                ></Video>
              </div>
              <Link to="../importance-of-stem" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <h3>
              Science is behind all of 3M’s innovations. Check out why students
              and scientists are passionate about both.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default WhyScience;
